import MainNavContainer from './Navigation/containers/MainNavContainer';
import MobileNavContainer from './Navigation/containers/MobileNavContainer';
import RightSideMenuContainer from './Navigation/containers/RightSideMenuContainer';
import Breadcrumbs from './Search/components/Breadcrumbs';
import NavSearchContainer from './Search/containers/NavSearchContainer';

const NavApps = {
  'main-nav': MainNavContainer,
  'mobile-nav': MobileNavContainer,
  'unifiedNav-search-app': NavSearchContainer,
  'right-side-menu': RightSideMenuContainer,
  'bread-crumbs': Breadcrumbs,
};

export default NavApps;
