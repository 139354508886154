import React from 'react';
import { useSelector } from 'react-redux';

import { slugify, titleCase } from '../../../../common/utils';
import { selectFormattedNavMenuCollections } from '../../../../ui/UiSelectors';
import { NavCategory } from '../../../../ui/UiTypes';
import { ColumnLink } from './ColumnLink';

export const NavMenuCollectionsLinkColumns = () => {
  const navMenuCollections = useSelector(selectFormattedNavMenuCollections);

  return (
    <>
      {Object.keys(navMenuCollections).map((contentClass) => (
        <React.Fragment key={contentClass}>
          <span className="text-gray-900 pt-2 font-bold block mt-5 lg:mt-0 lg:pl-5">
            {titleCase(contentClass)}&nbsp;Collections
          </span>
          {(navMenuCollections[contentClass] as NavCategory[])
            .filter((item) => !!item.url)
            .map((item) => (
              <ColumnLink
                item={item}
                key={item.url}
                containerProps={{
                  'data-cy': `${contentClass}-${slugify(item.title)}`,
                }}
                labelClassName={item.url.endsWith('collections') && 'font-bold'}
              />
            ))}
        </React.Fragment>
      ))}
    </>
  );
};
