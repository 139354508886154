import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { titleCase } from '../../../../common/utils';
import { isEqual } from '../../../../common/utils';
import {
  selectNavMenu,
  selectNavSubCategoriesForMobile,
} from '../../../../ui/UiSelectors';
import MenuHeader from './MenuHeader';
import SubcategoryLinksList from './SubcategoryLinksList';
import { mobileRightMenu } from './const';

interface RightMenuProps {
  menuRef: React.RefObject<HTMLDivElement>;
  selectedMenu: string;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
  selectedCategory: string;
}

const RightMenu = ({
  menuRef,
  selectedCategory = '',
  selectedMenu,
  setSelectedCategory,
}: RightMenuProps) => {
  const { top_level_categories } = useSelector(selectNavMenu);
  const subMenuCategories = useSelector(
    selectNavSubCategoriesForMobile(mobileRightMenu),
    isEqual
  );
  return (
    <div
      id="right-menu"
      className={twMerge(
        'w-full h-full pb-24 -ml-8 flex-shrink-0 scroll-smooth pt-3 min-h-screen',
        !selectedCategory && 'hidden'
      )}
      ref={menuRef}
    >
      <MenuHeader
        goUp={() => {
          setSelectedCategory('');
        }}
      />
      <div className="mobileXsell-subCategoryLinks mt-5 pl-5">
        <div className="flex-grow">
          <a
            className="text-gray-900 font-semibold mb-2"
            href={
              top_level_categories?.[selectedMenu]?.categories?.[
                selectedCategory
              ]?.url
            }
          >
            All {titleCase(selectedCategory.replace(/-/g, ' '))}
          </a>
        </div>
        {Object.keys(subMenuCategories).map((topLevelCategory) => {
          return Object.keys(subMenuCategories[topLevelCategory]).map(
            (categories) => (
              <div
                key={`${topLevelCategory}-${categories}`}
                className={
                  (selectedMenu !== topLevelCategory ||
                    categories !== selectedCategory) &&
                  'hidden'
                }
              >
                <SubcategoryLinksList
                  subCategoryList={
                    subMenuCategories[topLevelCategory][categories]
                  }
                />
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default RightMenu;
