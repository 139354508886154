import React from 'react';
import { twMerge } from 'tailwind-merge';

import { slugify, titleCase } from '../../../../common/utils';
import { NavCategory } from '../../../../ui/UiTypes';

export const ColumnTitle = ({
  item: { title },
  containerProps = {},
  labelClassName,
}: {
  item: NavCategory;
  containerProps?: Partial<{ className: string; [key: string]: string }>;
  labelClassName?: string;
}) => (
  <div
    className={twMerge(
      'unifiedNav-browseLink lg:m-0 mt-5 lg:h-8 lg:pt-1 lg:pl-5 pr-5',
      containerProps?.className
    )}
    key={title}
    data-cy={containerProps?.['data-cy'] || slugify(title)}
  >
    <div className="flex flex-row text-gray-900">
      <span
        className={twMerge(
          'float-left flex-grow truncate font-normal',
          labelClassName
        )}
      >
        {titleCase(title.replace(/-/g, ' '))}
      </span>
    </div>
  </div>
);
