import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import CollectionCard from '../../../../Collections/CollectionCard';
import { isCollectionItemNew, titleCase } from '../../../../common/utils';
import {
  selectNavMenu,
  selectNavSubcategoriesForCategory,
} from '../../../../ui/UiSelectors';
import { NavCategory } from '../../../../ui/UiTypes';
import CategoryLinksList from '../../../Search/components/nav/CategoryLinksList';
import NavBarBrowseLinksWithColumnHeaders from '../../../Search/components/nav/NavBarBrowseLinksWithColumnHeaders';
import { AudioOptions, VideoOptions } from '../../../Shared/constants';
import {
  AccountLinkColumns,
  ImageLinkColumns,
  MusicLinkColumns,
  NavMenuCollectionsLinkColumns,
  SoundFXLinkColumns,
  TemplateLinkColumns,
} from '../LinkColumns';
import MenuHeader from './MenuHeader';
import SubcategoryLinksList from './SubcategoryLinksList';
import { mobileMiddleMenu } from './const';

const { STOCK_FOOTAGE, BACKGROUNDS, TEMPLATES } = VideoOptions;
const { MUSIC, EFFECTS } = AudioOptions;
const ACCOUNT = 'account'; // matches NavBarViewModel::getInitialReduxState\\

interface MiddleMenuProps {
  menuRef: React.MutableRefObject<HTMLDivElement>;
  selectedMenu: string;
  selectedCategory: string;
  setSelectedMenu: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
}

const MiddleFrame = ({
  menuName,
  onClick,
  overrideMenu,
}: {
  menuName: string;
  onClick: (string) => void;
  overrideMenu: NavCategory[];
}) => {
  // SEO wants to bring enterprise up one level to be with categories
  if (menuName === 'enterprise') {
    return <SubcategoryLinksList subCategoryList={overrideMenu} />;
  }

  if ([STOCK_FOOTAGE, BACKGROUNDS].includes(menuName)) {
    return <CategoryLinksList menuName={menuName} onClick={onClick} />;
  }

  if (menuName === 'Collections') {
    return <NavMenuCollectionsLinkColumns />;
  }

  if (menuName === MUSIC) {
    return <MusicLinkColumns />;
  }

  if (menuName === TEMPLATES) {
    return <TemplateLinkColumns selectedMenu={'Templates'} />;
  }

  if (menuName === EFFECTS) {
    return <SoundFXLinkColumns />;
  }

  if (menuName === 'Images') {
    return <ImageLinkColumns />;
  }

  if (menuName === ACCOUNT) {
    return <AccountLinkColumns />;
  }

  return (
    <div className="subNav-subCategory gap-x-4 grid grid-cols-1">
      <NavBarBrowseLinksWithColumnHeaders menuName={menuName} />
    </div>
  );
};

const imagesUrl = __ASSETS_COMMON_IMAGES_URL__;

const MiddleMenu = ({
  menuRef,
  selectedMenu = '',
  selectedCategory,
  setSelectedMenu,
  setSelectedCategory,
}: MiddleMenuProps) => {
  const { top_level_categories } = useSelector(selectNavMenu);
  const enterpriseSubCategories = useSelector(
    selectNavSubcategoriesForCategory('business', 'enterprise')
  ).filter((subCategory) => subCategory.title !== 'License Comparison');

  const isEnterprise = selectedMenu === 'enterprise';

  const collectionsTitle = {
    'stock-footage': 'Stock Footage Collections',
    'animated-backgrounds': 'Animated Background Collections',
    Templates: 'Template Collections',
  };

  const collections = top_level_categories[selectedMenu]?.collections || [];
  const title = titleCase(selectedMenu.replace(/-/g, ' '));
  const header = isEnterprise ? 'Storyblocks for Business' : `All ${title}`;

  return (
    <div
      className={twMerge(
        'w-full h-full pb-24 -ml-8 flex-shrink-0 scroll-smooth pt-3 min-h-screen',
        (!selectedMenu || !!selectedCategory) && 'hidden'
      )}
      ref={menuRef}
    >
      <MenuHeader
        goUp={() => {
          setSelectedMenu('');
          setSelectedCategory('');
        }}
      />
      <div className="mobileXsell-subCategoryLinks mt-5 pl-4">
        <div className="flex flex-col mt-1 mb-3">
          <div className="flex-grow">
            <a
              className="text-gray-900 font-semibold mb-2"
              href={top_level_categories?.[selectedMenu]?.url}
            >
              {header}
            </a>
          </div>
          {mobileMiddleMenu.map((category, idx) => {
            return (
              <div
                key={idx}
                className={category !== selectedMenu ? 'hidden' : ''}
              >
                <MiddleFrame
                  menuName={category}
                  onClick={setSelectedCategory}
                  overrideMenu={enterpriseSubCategories}
                />
              </div>
            );
          })}
        </div>
        {!!collections.length && (
          <>
            <div className="text-gray-900 font-semibold mb-2 mt-7">
              {collectionsTitle[selectedMenu]}
            </div>
            <div className="grid gap-4 grid-cols-1 ppro-sm:grid-cols-2 pr-7">
              {collections.map((collection) => {
                const {
                  id,
                  contentClasses,
                  view: { heroTitle, routeUri, directoryBackgroundImageUrl },
                } = collection;
                return (
                  <CollectionCard
                    key={id}
                    label={heroTitle}
                    href={routeUri}
                    thumbnailSrc={directoryBackgroundImageUrl}
                    thumbnailAlt={directoryBackgroundImageUrl}
                    labelClassName="text-xs px-2 pb-2.5 pt-1.5"
                    iconContentClasses={contentClasses}
                    showNewLabel={isCollectionItemNew(collection)}
                    newLabelClassName="top-2 left-2"
                    imagesUrl={imagesUrl}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MiddleMenu;
