import React from 'react';
import { useSelector } from 'react-redux';

import siteConstants, {
  SiteEnum,
} from '../../../../common/SiteConstants/SiteConstants';
import { VideoNavOptions } from '../../../../common/SiteConstants/VideoSiteConstants';
import { selectItemsByMenu } from '../../../../ui/UiSelectors';
import { ColumnLink } from './ColumnLink';

const videoOptions = siteConstants
  .buildInstance(SiteEnum.Videoblocks)
  .getStockMediaNavOptions() as VideoNavOptions;

export const TemplateLinkColumns = ({
  selectedMenu = videoOptions.TEMPLATES,
}: {
  selectedMenu?: string;
}) => {
  const items = useSelector(selectItemsByMenu(selectedMenu));

  return (
    <>
      {items.map((item) => (
        <ColumnLink
          item={item}
          key={item.url}
          labelClassName={item.title.endsWith('Templates') && 'font-bold'}
        />
      ))}
    </>
  );
};
