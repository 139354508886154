import React from 'react';
import { twMerge } from 'tailwind-merge';

import Logger from '../../../../common/Logger';
import { slugify, titleCase } from '../../../../common/utils';
import { NavCategory } from '../../../../ui/UiTypes';

export const ColumnLink = ({
  item: { title, url, telemetry },
  containerProps = {},
  labelClassName,
}: {
  item: NavCategory;
  containerProps?: Partial<{ className: string; [key: string]: string }>;
  labelClassName?: string;
}) => {
  const handleClick = () => {
    if (telemetry) {
      Logger.accessTelemetry().increment(telemetry);
    }
  };

  // i.e. <CategoryLink />
  return (
    <div
      role="button"
      tabIndex={0}
      className={twMerge(
        'unifiedNav-browseLink lg:m-0 mt-5 lg:h-8 lg:pt-1 hover:bg-blue-100 lg:pl-5 pr-5',
        containerProps?.className
      )}
      onClick={handleClick}
      onKeyDown={handleClick}
      key={url}
      data-cy={containerProps?.['data-cy'] || slugify(title)}
    >
      <a
        className="flex flex-row text-gray-900"
        tabIndex={0}
        href={url}
        onClick={() => {
          if (telemetry) Logger.accessTelemetry().increment(telemetry);
        }}
      >
        <span
          className={twMerge(
            'float-left flex-grow truncate font-normal',
            labelClassName
          )}
        >
          {titleCase(title.replace(/-/g, ' '))}
        </span>
      </a>
    </div>
  );
};
