import { NavCategory } from '../../ui/UiTypes';

export const VideoOptions = {
  STOCK_FOOTAGE: 'stock-footage',
  BACKGROUNDS: 'animated-backgrounds',
  TEMPLATES: 'Templates',
};

export const TemplateOptions = {
  PREMIERE_PRO: 'premiere-pro-templates',
  AFTER_EFFECTS: 'after-effects-templates',
  DAVINCI_RESOLVE: 'davinci-resolve-templates',
  APPLE_MOTION: 'apple-motion-templates',
};

export const AudioOptions = {
  MUSIC: 'royalty-free-music',
  EFFECTS: 'sound-effects',
};

export const imageMenu: NavCategory[] = [
  {
    url: '/images/vectors',
    title: 'Vectors',
  },
  {
    url: '/images/illustrations',
    title: 'Illustrations',
  },
  {
    url: '/images/photos',
    title: 'Photos',
  },
  {
    url: '/images/collections',
    title: 'Collections',
  },
];
