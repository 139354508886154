import React from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronRight } from '@videoblocks/react-icons';

import { titleCase, slugify } from '../../../../common/utils';

export interface MenuOptionProps {
  name: string;
  href?: string;
  // https://legacy.reactjs.org/docs/events.html#mouse-events
  event?: 'click' | 'mouseEnter' | 'mouseLeave' | 'mouseOver' | 'mouseOut';
  action?: (name: string) => void;
  showChevron?: boolean;
  className?: string;
  childClassName?: string;
  label?: string;
}

export const MenuOption = ({
  name,
  label,
  action,
  event = 'click',
  href,
  showChevron = true,
  className,
  childClassName,
}: MenuOptionProps) => {
  // only apply non-TW classes if none passed (twMerge takes care of TW ones).
  // only apply p-0 if no (other) overrides--tailwind doesn't provide a type/rule
  // to unset specific rules (i.e. p-unset)
  const nonTwClasses =
    'mobileXsell-menuCategory mobileXsell-menuSubCategory p-0';
  const cls = twMerge(
    'h-auto cursor-pointer text-gray-900 font-semibold',
    className || nonTwClasses
  );
  const title = label || titleCase(name.replace(/-/g, ' '));

  const children = (
    <div
      className={twMerge(
        'flex items-center justify-between px-6 py-4 menuOption-text hover:bg-gray-150',
        childClassName || 'mobileXsell-menuCategoryLabel'
      )}
    >
      {title}
      {showChevron && (
        <ChevronRight className="fill-current text-gray-900 w-3 h-3 mobileXsell-expanderIcon" />
      )}
    </div>
  );

  const elementProps = {
    className: cls,
    'data-cy': slugify(name),
  };

  if (event && action) {
    elementProps[`on${event[0].toUpperCase()}${event.slice(1)}`] = () =>
      action(name);
  }

  if (href) {
    return (
      <a
        href={href}
        {...elementProps}
        className="h-auto cursor-pointer text-gray-900 font-semibold option no-underline"
      >
        {children}
      </a>
    );
  }

  return <div {...elementProps}>{children}</div>;
};
