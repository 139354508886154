import React from 'react';
import { twMerge } from 'tailwind-merge';

import { Close } from '@videoblocks/react-icons';

import LeftMenu from '../components/MobileNav/LeftMenu';
import MiddleMenu from '../components/MobileNav/MiddleMenu';
import RightMenu from '../components/MobileNav/RightMenu';

const iconClassNames = 'fill-current text-gray-900 w-3 h-3';

const MobileNavContainer = () => {
  // MobileHamburgerMenuWidgetView.php
  const [selectedMenu, setSelectedMenu] = React.useState('');
  const [selectedCategory, setSelectedCategory] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);

  const mobileNavRef = React.useRef<HTMLDivElement>();
  const menuContainer = React.useRef<HTMLDivElement>();
  const categoryContainer = React.useRef<HTMLDivElement>();
  const subcategoryContainer = React.useRef<HTMLDivElement>();
  const toggleMenu = () => {
    if (isOpen) {
      // if closing, un-set state
      setSelectedMenu('');
      setSelectedCategory('');
    }
    setIsOpen(!isOpen);
  };

  return (
    <div id="mobileXSell-dropdown" className="dropdown box-border relative">
      <button
        className="hover:text-gray-900 text-2xl text-gray-700 bg-transparent border-none cursor-pointer"
        id="hamburger-menu-button"
        onClick={toggleMenu}
      >
        &#9776;
      </button>
      <div
        ref={mobileNavRef}
        className={twMerge(
          'mobileXsell-menu custom-scroll flex overflow-x-auto transition duration-200 ease-in-out  z-1000 float-left float-left list-none text-base text-left bg-white border border-gray-300 rounded-sm shadow-md fixed left-0 w-full max-h-screen p-0 m-0 overflow-y-auto',
          isOpen ? 'top-0' : 'hidden'
        )}
      >
        <button
          className="sticky h-10 p-3 justify-center items-center hover:text-gray-900 text-3xl leading-8 text-gray-700 bg-transparent border-none cursor-pointer"
          title="Close"
          style={{ zIndex: 100, left: 'calc(100vw - 2.5rem)' }}
          onClick={toggleMenu}
        >
          <Close className={iconClassNames} />
        </button>
        <LeftMenu
          menuRef={menuContainer}
          setSelectedMenu={setSelectedMenu}
          selectedMenu={selectedMenu}
        />
        <MiddleMenu
          menuRef={categoryContainer}
          {...{
            selectedMenu,
            selectedCategory,
            setSelectedMenu,
            setSelectedCategory,
          }}
        />
        <RightMenu
          menuRef={subcategoryContainer}
          {...{
            selectedCategory,
            selectedMenu,
            setSelectedCategory,
          }}
        />
      </div>
    </div>
  );
};

export default MobileNavContainer;
