import React from 'react';

import { InterfaceArrowLeft } from '@videoblocks/react-icons';

const MenuHeader = ({ goUp }: { goUp: () => void }) => (
  <div className="flex flex-row">
    <div
      className="p-3"
      role="button"
      tabIndex={0}
      onClick={goUp}
      onKeyDown={goUp}
    >
      <InterfaceArrowLeft className="fill-current text-gray-900 w-3 h-3 h-5 w-5" />
    </div>
  </div>
);

export default MenuHeader;
