import React from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { AudioNavOptions } from '../../../../common/SiteConstants/AudioSiteConstants';
import siteConstants, {
  SiteEnum,
} from '../../../../common/SiteConstants/SiteConstants';
import useMedia from '../../../../common/hooks/useMedia';
import { titleCase } from '../../../../common/utils';
import { selectNavMenuCategory } from '../../../../ui/UiSelectors';
import { NavCategory } from '../../../../ui/UiTypes';
import { ColumnLink } from './ColumnLink';
import { ColumnTitle } from './ColumnTitle';

const audioOptions = siteConstants
  .buildInstance(SiteEnum.Audioblocks)
  .getStockMediaNavOptions() as AudioNavOptions;

export const MusicLinkColumns = () => {
  const { categories } = useSelector(selectNavMenuCategory(audioOptions.MUSIC));
  const isSmallScreen = useMedia('(max-width: 673px)', true);
  const ITEMS_PER_ROW = 10;

  return (
    <>
      {!isSmallScreen && (
        <div className="pl-5 pb-2 w-full pr-3 grid grid-rows-1 grid-cols-4 gap-x-7">
          {Object.keys(categories).map((key) => (
            <div
              className={twMerge(
                'font-bold text-gray-900',
                key === 'genres' && 'col-span-2'
              )}
              key={key}
            >
              {titleCase(key)}
            </div>
          ))}
        </div>
      )}
      <div
        className={twMerge(
          'subNav-subCategory',
          isSmallScreen
            ? 'flex-grow'
            : `grid grid-flow-col grid-cols-4 gap-y-1.5 grid-rows-${ITEMS_PER_ROW}`
        )}
      >
        {Object.keys(categories).map((key) => {
          let { subCategories: items } = categories[key];

          const itemsShortOfRowLength = Math.abs(ITEMS_PER_ROW - items.length);

          if (isSmallScreen) {
            // inline category on mobile
            items = [{ url: '', title: key }, ...items];
          } else if (itemsShortOfRowLength < ITEMS_PER_ROW) {
            items = [
              ...items,
              ...(Array.from({ length: itemsShortOfRowLength }).fill(
                {}
              ) as NavCategory[]),
            ];
          }

          return items.map((item) => {
            if (!item.title) {
              return <div key={item.url} />;
            }
            if (!item.url) {
              return (
                <ColumnTitle
                  key={item.title}
                  item={item}
                  containerProps={{
                    className: twMerge('pr-3.5 mt-7'),
                  }}
                  labelClassName="font-bold"
                />
              );
            }

            return (
              <ColumnLink
                item={item}
                key={item.url}
                containerProps={{
                  className: twMerge('pr-3.5'),
                }}
              />
            );
          });
        })}
      </div>
    </>
  );
};
