import React from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { AudioNavOptions } from '../../../../common/SiteConstants/AudioSiteConstants';
import siteConstants, {
  SiteEnum,
} from '../../../../common/SiteConstants/SiteConstants';
import { selectItemsByMenu } from '../../../../ui/UiSelectors';
import { ColumnLink } from './ColumnLink';

const audioOptions = siteConstants
  .buildInstance(SiteEnum.Audioblocks)
  .getStockMediaNavOptions() as AudioNavOptions;

export const SoundFXLinkColumns = ({
  rowsPerColumn,
}: {
  rowsPerColumn?: number;
}) => {
  const items = useSelector(selectItemsByMenu(audioOptions.EFFECTS));

  /**
   * because these items are automatically laid out in a css grid w/rows & cols,
   * we don't have a great way to target items just in the last column (yet) [0].
   * instead, calculate the offset idx where the last column starts, style
   *
   * [0] `:nth-col()` and `:nth-last-col()` pseudo-selectors are incoming at some
   * point, don't have any browser support as of this implementation
   * https://www.w3.org/TR/selectors-4/#table-pseudos
   * https://developer.mozilla.org/en-US/docs/Web/CSS/:nth-col
   */
  const numberOfItemsInLastColumn =
    items.length % (rowsPerColumn || items.length);
  const indexOfLastColumn = items.length - numberOfItemsInLastColumn;

  return (
    <>
      {items.map((item, idx) => (
        <ColumnLink
          item={item}
          key={item.url}
          containerProps={{
            className: twMerge(
              'lg:pl-4',
              idx >= indexOfLastColumn ? 'pr-3' : 'pr-2'
            ),
          }}
        />
      ))}
    </>
  );
};
