import React from 'react';

import { imageMenu } from '../../../Shared/constants';
import { ColumnLink } from './ColumnLink';

export const ImageLinkColumns = () => {
  return (
    <div className="subNav-subCategory gap-x-4 grid grid-cols-1">
      {imageMenu.map((item) => {
        return <ColumnLink item={item} key={item.url} />;
      })}
    </div>
  );
};
