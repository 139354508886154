import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import {
  selectIsIpAuth,
  selectIsLoggedIn,
  selectUser,
} from '../../../../auth/AuthSelectors';
import {
  selectNavMenu,
  selectNavMenuCategory,
} from '../../../../ui/UiSelectors';
import { OtherCategoryTypes } from '../../../../ui/UiTypes';
import { MobileCreativeToolsLinksList } from '../../../Search/components/nav/CategoryLinksList';
import { MenuOption, MenuOptionProps } from './MenuOption';
import TalkToSalesButton from './TalkToSalesButton';
import { mobileNavTopLevelCategories } from './const';

const ACCOUNT = 'account'; // matches NavBarViewModel::getInitialReduxState

interface LeftMenuProps {
  menuRef: React.LegacyRef<HTMLDivElement>;
  setSelectedMenu: React.Dispatch<React.SetStateAction<string>>;
  selectedMenu: string;
}

const Header: React.FC = ({ children }) => (
  <div className="mobileXsell-menuCategory h-auto p-0 cursor-pointer">
    <div className="mobileXsell-menuCategoryLabel flex items-center justify-between px-7 pt-10 pb-2 font-semibold">
      {children}
    </div>
  </div>
);

const LeftMenu = ({
  menuRef,
  setSelectedMenu,
  selectedMenu,
}: LeftMenuProps) => {
  const { showFavorites } = useSelector(selectNavMenu);
  const user = useSelector(selectUser);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isIpAuth = useSelector(selectIsIpAuth);
  const businessCategories = useSelector(
    selectNavMenuCategory(OtherCategoryTypes.BUSINESS)
  ).categories;
  const resourcesCategories = useSelector(
    selectNavMenuCategory(OtherCategoryTypes.RESOURCES)
  );
  const showAccountNav = isLoggedIn && !isIpAuth;

  return (
    <div
      className={twMerge(
        'w-full h-full pb-24 -ml-8 flex-shrink-0 scroll-smooth min-h-screen',
        !!selectedMenu && 'hidden'
      )}
      ref={menuRef}
    >
      <div className="mobileXsell-menuCategoryContent">
        <div className="mobileXsell-menuCategoryList">
          <Header>Stock Media</Header>
        </div>
        <MenuOption
          name="Video"
          childClassName="py-2 pl-7 pr-5 leading-normal mobileXsell-menuCategoryLabel no-underline font-normal"
          href="/video"
        />
        {mobileNavTopLevelCategories.map((topLevelCategoryName) => (
          <MenuOption
            name={topLevelCategoryName}
            key={topLevelCategoryName}
            className="font-normal"
            childClassName="py-2 pl-7 pr-5 leading-normal mobileXsell-menuCategoryLabel no-underline"
            action={setSelectedMenu}
          />
        ))}
        <Header>Creative Tools</Header>
        <MobileCreativeToolsLinksList
          menuName="creative-tools"
          buttonClassName="mt-0 hover:bg-gray-150 py-2 px-7"
        />
        <Header>Businesses</Header>
        <MenuOption
          childClassName="py-2 px-7 leading-normal no-underline"
          className="font-normal"
          name="License Comparison"
          href="/business-solutions/license-comparison"
          showChevron={false}
        />
        {Object.keys(businessCategories).map((categoryName) => {
          const hasSubCategories =
            !!businessCategories[categoryName].subCategories.length;
          const isEnterprise = categoryName === 'enterprise';
          let label;
          if (isEnterprise) {
            label = 'Use Cases';
          } else if (categoryName === 'storyblocks-api') {
            label = 'Storyblocks API';
          }
          return (
            <Fragment key={categoryName}>
              {isEnterprise && (
                <MenuOption
                  name="enterprise"
                  label="Learn More"
                  className="font-normal"
                  childClassName="py-2 pl-7 pr-5 leading-normal"
                  href={businessCategories[categoryName].url}
                  showChevron={false}
                />
              )}
              <MenuOption
                name={categoryName}
                label={label}
                className="font-normal"
                childClassName="py-2 px-7 leading-normal no-underline"
                showChevron={hasSubCategories}
                href={
                  hasSubCategories ? null : businessCategories[categoryName].url
                }
                action={hasSubCategories ? setSelectedMenu : null}
              />
            </Fragment>
          );
        })}
        <TalkToSalesButton className="py-2 px-7" />

        <Header>Resources</Header>
        {Object.keys(resourcesCategories?.categories).map((categoryName) => {
          const props: MenuOptionProps = {
            name: categoryName,
            className: 'font-normal',
            childClassName: 'py-2 px-7 leading-normal',
            showChevron: false,
            href: resourcesCategories?.categories[categoryName].url,
          };

          if (categoryName.toLowerCase().includes('faq')) {
            props.label = 'FAQs';
          }

          return <MenuOption key={categoryName} {...props} />;
        })}

        {user && showFavorites && (
          <MenuOption
            name="Folders"
            href="/member/folders"
            childClassName="py-2 px-7 leading-normal"
            showChevron={false}
          />
        )}
      </div>

      {user && showAccountNav && (
        <MenuOption
          childClassName="py-2 px-7 leading-normal"
          name={ACCOUNT}
          action={setSelectedMenu}
        />
      )}
      {!user && (
        <>
          <MenuOption
            childClassName="py-2 px-7 leading-normal pt-6"
            name="Pricing"
            href="/pricing"
            showChevron={false}
          />
          <div className="flex flex-col items-center mb-6">
            <a
              href="/login"
              className="btn btn-primary btn-wide"
              id="mobile-login-link"
            >
              Login
            </a>
            <a className="text-blue-500" href="/sign-up">
              Create Account
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default LeftMenu;
