import React from 'react';
import { twMerge } from 'tailwind-merge';

import { NavCategory } from '../../../../ui/UiTypes';
import { ColumnLink } from '../LinkColumns';

export const SubcategoryLinkColumns = ({
  subCategoryList = [],
}: {
  subCategoryList: NavCategory[];
}) => (
  <>
    {subCategoryList.map((item) => (
      <ColumnLink
        item={item}
        key={item.title}
        containerProps={{
          className: 'mt-3 lg:mt-0 sm:pr-3',
        }}
      />
    ))}
  </>
);

const SubcategoryLinksList = ({
  containerProps,
  subCategoryList = [],
}: {
  containerProps?: Partial<{ className: string; style: object }>;
  subCategoryList: NavCategory[];
}) => (
  // todo: consolidate with CategoryLinksList
  <div
    className={twMerge(
      containerProps?.className,
      'subNav-subCategory leading-normal lg:px-0 pr-5 grid md:grid-cols-2 md:grid-flow-col md:grid-rows-10'
    )}
    style={containerProps?.style}
  >
    <SubcategoryLinkColumns subCategoryList={subCategoryList} />
  </div>
);

export default SubcategoryLinksList;
