import React from 'react';
import { useSelector } from 'react-redux';

import { selectNavMenu } from '../../../../ui/UiSelectors';
import { ColumnLink } from './ColumnLink';

export const AccountLinkColumns = () => {
  const { account } = useSelector(selectNavMenu);

  return (
    <div className="subNav-subCategory gap-x-4 grid grid-cols-1">
      {Object.values(account).map((menu) => (
        <ColumnLink item={menu} key={menu.url} />
      ))}
    </div>
  );
};
